import React, { FC } from 'react';
import loadable from '@loadable/component';
import type { OpenSourceSchema } from '../../schemas/opensource';
import * as styles from './repository.module.scss';
import { GrGithub } from 'react-icons/gr';

const DyamicRepoStats = loadable(() => import(/* webpackChunkName: "dynamicRepoStats" */ './DynamicRepoStats'));

export interface RepositoryProps {
	title: string;
	context: OpenSourceSchema;
}

export const Repository: FC<RepositoryProps> = ({ title, context }) => {
	return (
		<article className={styles.root}>
			<h4 className={styles.title}>{title}</h4>
			<section className={styles.stats}>
				<DyamicRepoStats context={context} url={context.repository} />
			</section>
			<a
				className={`${styles.link} --no-indicator`}
				href={context.repository}
				target="_blank"
				rel="noopener noreferrer"
				title={`View ${context.repository.replace('https://github.com/', '')}`}
			>
				<GrGithub /> View on GitHub
			</a>
		</article>
	);
};

Repository.displayName = 'Repository';
