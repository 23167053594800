import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import { ContentConstraint } from '../../components/ContentConstraint/ContentConstraint';
import { ListArticle, ListArticleItem } from '../../components/ListArticle/ListArticle';
import { StarRating } from '../../components/StarRating/StarRating';
import { GatsbyStaticData } from '../../schemas/gatsby';
import { SkillsSchema } from '../../schemas/skills';

import * as styles from './skills.module.scss';

interface SkillsResponse {
	skills: GatsbyStaticData<SkillsSchema>;
}

export const Skills: FC = () => {
	const { skills } = useStaticQuery<SkillsResponse>(graphql`
		{
			skills: allSkillsJson {
				edges {
					node {
						id
						title
						color
						items {
							title
							rating
						}
					}
				}
			}
		}
	`);

	return (
		<ContentConstraint>
			<div className="row">
				{skills.edges.map(({ node }) => (
					<div key={node.id} className="col-xs-12 col-xl-6">
						<ListArticle title={node.title}>
							{node.items.map(({ title, rating }) => (
								<ListArticleItem key={`${node.id}_${title.toLowerCase()}`}>
									<p className={styles.entry}>
										{title} <StarRating fill={node.color} value={rating} scale={5} />
									</p>
								</ListArticleItem>
							))}
						</ListArticle>
					</div>
				))}
			</div>
		</ContentConstraint>
	);
};
