import React, { FC } from 'react';
import { CodeSnippet } from '../../components/CodeSnippet/CodeSnippet';

export const KeybasePGP: FC = () => (
	<CodeSnippet
		caption="PGP Key"
		note={
			<>
				Visit{' '}
				<a href="https://keybase.io/janbiasi#show-public" target="_blank" rel="noopener noreferrer">
					Keybase
				</a>{' '}
				for more details
			</>
		}
	>
		87D4 259A EF88 385E 8562 1C05 513E 0E6A 73FD 5642
	</CodeSnippet>
);

KeybasePGP.displayName = 'KeybasePGP';
