import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import type { ExperienceSchema } from '../../schemas/experience';
import type { GatsbyStaticData } from '../../schemas/gatsby';
import type { OpenSourceSchema } from '../../schemas/opensource';
import type { ProjectSchema } from '../../schemas/project';
import type { VolunteerSchema } from '../../schemas/volunteer';
import { ContentConstraint } from '../../components/ContentConstraint/ContentConstraint';
import { ListArticle, ListArticleProjectItem } from '../../components/ListArticle/ListArticle';
import { Repository } from '../../components/Repository/Repository';
import { GenericArticle } from '../../components/GenericArticle/GenericArticle';

interface CVResponse {
	projects: GatsbyStaticData<ProjectSchema>;
	opensource: GatsbyStaticData<OpenSourceSchema>;
	experience: GatsbyStaticData<ExperienceSchema>;
	volunteer: GatsbyStaticData<VolunteerSchema>;
}

export const CV: FC = () => {
	const { projects, opensource, experience, volunteer } = useStaticQuery<CVResponse>(graphql`
		{
			projects: allProjectsJson {
				edges {
					node {
						id
						slug
						name
						category
						role
						url
					}
				}
			}
			experience: allExperienceJson {
				edges {
					node {
						id
						company
						url
						role
						start
						end
					}
				}
			}
			opensource: allOpensourceJson {
				edges {
					node {
						id
						name
						category
						relation
						repository
					}
				}
			}
			volunteer: allVolunteerJson {
				edges {
					node {
						id
						company
						role
						start
						url
					}
				}
			}
		}
	`);

	return (
		<ContentConstraint>
			<ListArticle title="Experience">
				{experience.edges
					.sort((_, { node }) => (node.end === null ? 1 : -1))
					.map(({ node }) => (
						<ListArticleProjectItem
							key={node.id}
							title={node.company}
							url={node.url}
							subtitle={node.role}
							meta={`${node.start} - ${node.end || 'Now'}`}
						/>
					))}
			</ListArticle>
			<ListArticle title="Volunteering">
				{volunteer.edges
					.sort((_, { node }) => (node.end === null ? 1 : -1))
					.map(({ node }) => (
						<ListArticleProjectItem
							key={node.id}
							title={node.company}
							url={node.url}
							subtitle={node.role}
							meta={`${node.start} - ${node.end || 'Now'}`}
						/>
					))}
			</ListArticle>
			<ListArticle title="Projects">
				{projects.edges.map(({ node }) => (
					<ListArticleProjectItem
						key={node.id}
						title={node.name}
						url={`/projects/${node.slug}`}
						subtitle={node.category}
						meta={node.role}
					/>
				))}
			</ListArticle>
			<GenericArticle title="OpenSource">
				<div className="row">
					{opensource.edges.map(({ node }) => (
						<div key={node.id} className="col-xs-12 col-sm-12 col-lg-6 col-xl-4">
							<Repository title={node.name} context={node} />
						</div>
					))}
				</div>
			</GenericArticle>
		</ContentConstraint>
	);
};

CV.displayName = 'CV';
