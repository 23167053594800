// extracted by mini-css-extract-plugin
export var root = "star-rating-module--root--2F6PV";
export var empty = "star-rating-module--empty--2T4BB";
export var filled = "star-rating-module--filled--3vF9_";
export var i1 = "star-rating-module--i1--l3TSf";
export var i2 = "star-rating-module--i2--1BsuN";
export var i3 = "star-rating-module--i3--_-D2c";
export var i4 = "star-rating-module--i4--1JoZR";
export var i5 = "star-rating-module--i5--2I0OB";
export var turquoise = "star-rating-module--turquoise--3-YO-";
export var white = "star-rating-module--white--2InFu";
export var pink = "star-rating-module--pink--1JiSf";
export var yellow = "star-rating-module--yellow--OzNj0";
export var blue = "star-rating-module--blue--2iUEL";