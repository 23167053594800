import React, { FC } from 'react';
import { GrStar } from 'react-icons/gr';

import * as styles from './star-rating.module.scss';

type StyleKeys = keyof typeof styles;

export interface StarRatingProps {
	value: number;
	scale?: number;
	className?: string;
	fill?: 'pink' | 'white' | 'yellow' | 'turquoise' | 'blue';
}

export const StarRating: FC<StarRatingProps> = ({ className, value, scale = 5, fill = 'turquoise' }) => {
	const entries = new Array(scale).fill(null);

	if (scale > 5) {
		throw new Error(`StarRating: scale only supported until 5, please adjust the scale prop.`);
	}

	const defaultClass = `${className || ''} ${styles[fill]}`;

	return (
		<div className={styles.root}>
			{entries.map((_, index) =>
				value <= index ? (
					<GrStar key={index} className={`${defaultClass} ${styles.empty} ${styles[`i${index + 1}` as StyleKeys]}`} />
				) : (
					<GrStar key={index} className={`${defaultClass} ${styles.filled} ${styles[`i${index + 1}` as StyleKeys]}`} />
				)
			)}
		</div>
	);
};
