import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import { SEO } from '../components/SEO/SEO';
import { Stage } from '../components/Stage/Stage';
import { Layout } from '../fragments/Layout/Layout';
import { CV } from '../fragments/CV/CV';
import { Skills } from '../fragments/Skills/Skills';
import { KeybasePGP } from '../fragments/KeybasePGP/KeybasePGP';
import { Configurator } from '../fragments/Configurator/Configurator';

const IndexPage: FC<PageProps> = ({ uri }) => {
	return (
		<Configurator>
			<SEO
				url={uri}
				description="I'm a software engineer, event manager, musician and blockchain partisan based in Switzerland."
			/>
			<Layout>
				<Stage
					title="Hello there!"
					lead={
						<span>
							I'm a software engineer, event manager,{' '}
							<a href="https://aviormusic.com" target="_blank" rel="noopener noreferrer">
								musician
							</a>{' '}
							&amp; blockchain partisan based in Switzerland.
						</span>
					}
				/>
				<CV />
				<Skills />
				<KeybasePGP />
			</Layout>
		</Configurator>
	);
};

export default IndexPage;
