import React, { FC } from 'react';
import { GrInfo } from 'react-icons/gr';
import { ContentConstraint } from '../ContentConstraint/ContentConstraint';
import * as styles from './code-snippet.module.scss';

export interface CodeSnippetProps {
	caption?: string | JSX.Element;
	note?: string | JSX.Element;
}

export const CodeSnippet: FC<CodeSnippetProps> = ({ caption, note, children }) => (
	<ContentConstraint className={styles.root}>
		{caption && <h5 className={styles.caption}>{caption}</h5>}
		<code>{children}</code>
		{note && (
			<p className={styles.note}>
				<GrInfo />
				{note}
			</p>
		)}
	</ContentConstraint>
);

CodeSnippet.displayName = 'Fingerprint';
