import { Link } from 'gatsby';
import React, { FC } from 'react';
import { GenericArticle, GenericArticleProps } from '../GenericArticle/GenericArticle';
import * as styles from './list-article.module.scss';

export interface ListArticleProps extends GenericArticleProps {}

export const ListArticle: FC<ListArticleProps> = ({ title, children, disableAnchor = true }) => {
	return (
		<GenericArticle title={title} disableAnchor={disableAnchor}>
			<ul className={styles.list}>{children}</ul>
		</GenericArticle>
	);
};

ListArticle.displayName = 'ListArticle';

export interface ListArticleItemProps {
	className?: string;
}

export const ListArticleItem: FC<ListArticleItemProps> = ({ children, className }) => (
	<li className={className}>{children}</li>
);

ListArticleItem.displayName = 'ListArticleItem';

export interface ListArticleProjectItemProps extends ListArticleItemProps {
	title: string;
	url?: string;
	subtitle?: string;
	meta?: string;
}

export const ListArticleProjectItem: FC<ListArticleProjectItemProps> = ({ className, title, url, subtitle, meta }) => (
	<li className={`${styles.item} ${className || ''}`}>
		{url && url.includes('http') && (
			<a href={url} rel="noopener noreferrer" target="_blank" className={`p ${styles.title}`}>
				{title}
			</a>
		)}
		{url && !url.includes('http') && (
			<Link to={url} className={`p ${styles.title}`}>
				{title}
			</Link>
		)}{' '}
		{!url && <h4 className={`p ${styles.title}`}>{title} </h4>}{' '}
		{subtitle && (
			<p className={`p ${styles.subtitle}`}>
				<span className={styles.separator}>|</span> {subtitle}
			</p>
		)}{' '}
		{meta && (
			<p className={`p ${styles.meta}`}>
				<span className={styles.separator}>|</span> {meta}
			</p>
		)}
	</li>
);

ListArticleProjectItem.displayName = 'ListArticleProjectItem';
